
.navbar-dark.navbar-vibrant {
    background-image: -webkit-gradient(linear, left top, right bottom, from(#003CC2), to(#010034)), url(/img/covers/pic1.jpg);
    background-image: linear-gradient(to bottom right, #003cc2bf, #010034), url(/img/covers/pic1.jpg);
    background-repeat: no-repeat, no-repeat;
    background-position: center center, center center;
    background-size: cover, cover;
    background-origin: border-box;
    border-color: transparent;
}

.bg-auth {
    background-color: #000033 !important;
}

nav#topbar {
    margin-left: 66px;

    &.sidebar-open
    {
        margin-left: 250px;
    }

    @media(max-width:767px)
    {
        display:none;
    }
}

.avatar-model .avatar-title {
    font-size: 1.2em;
}

.avatar-name {
    text-align: center;
    width: 100%;
    display: block;
    color: #FFF;
    text-transform: uppercase;
    font-size: .8em;
    margin-top: 5px;
}

.avatar-icon {
    padding: 1vh;
}

.model-icon {
    height:auto !important;

    svg path {
        fill: #FFF;
    }
}

/* Chrome, Safari, Edge, Opera */
input.hide-number-arrows::-webkit-outer-spin-button,
input.hide-number-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.hide-number-arrows[type=number] {
    -moz-appearance: textfield;
}
