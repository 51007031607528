/**
 * Dashkit v1.4.2
 *
 * Custom variables followed by Dashkit variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */

// Bootstrap functions
@import '../../../../node_modules/bootstrap/scss/functions';

// Custom variables
@import '../user-variables';

// Dark mode variables
@import 'dark/variables';

// Dashkit variables
@import '../variables';

// Bootstrap core
@import '../../../../node_modules/bootstrap/scss/bootstrap';

// Dashkit core
@import '../../../theme/Dashkit/Dashkit-1.6.0/src/assets/scss/dashkit/dashkit';

// Dark mode overrides
@import 'dark/overrides';

@import '../custom';
