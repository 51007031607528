//
// overrides.scss
// Dark mode overrides
//

//
// Table of contents
//
// 1. Buttons
// 2. Forms
// 3. Input groups
// 4. Quill
// 5. Select2
//


// Buttons

.btn-white, .btn-light {
    @include button-variant($gray-800-dark, $gray-600-dark);

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle {
        background-color: $black-dark;
        border-color: $gray-700-dark;
        color: $white;
    }
}


// Forms

.form-control {
    border-color: $input-bg;
}


// Input groups

.input-group .input-group-text {
    border-color: $input-bg;
}


// Quill

.ql-toolbar {
    border-color: $input-bg;
}

.ql-editor {
    border-left-color: $input-bg;
    border-right-color: $input-bg;
    border-bottom-color: $input-bg;
}


// Select2

.select2-container .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: $input-bg;
}
